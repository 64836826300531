import find from 'lodash/find'

export default function ({ store, redirect }) {
  if (store.state.loggedIn && !isAdminPayment(store.state.users.user)) {
    return redirect('/forbidden')
  }
}

// Vistas abiertas solo para 'superadmin', 'admin', 'tesorero'
function isAdminPayment(user) {
  let isAdmin = false
  if (user.role === 'superadmin') {
    // If user is superadmin can access to view
    return true
  } else if (user.auxRole && user.auxRole === 'officer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'payments'
    })
    isAdmin = !hasModule ? false : hasModule.role === 'tesorero'
  } else if (user.auxRole && user.auxRole === 'visualizer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'payments'
    })
    isAdmin = !!hasModule
  }
  return isAdmin
}
