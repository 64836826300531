import includes from 'lodash/includes'

export default function ({ store, redirect }) {
  if (isFunctionary(store.state.users.user)) {
    return redirect('/')
  }
}

function isFunctionary(user) {
  return (
    user &&
    user.role &&
    (includes(
      ['superadmin', 'admin', 'editor', 'operator', 'communicator'],
      user.role
    ) ||
      includes(['officer', 'visualizer'], user.auxRole))
  )
}
