import includes from 'lodash/includes'
import find from 'lodash/find'

export default function ({ store, redirect, route }) {
  if (store.state.loggedIn && !isSuperAdmin(store.state.users.user)) {
    if (
      store.state.loggedIn &&
      (isAdmin(store.state.users.user) ||
        isVisualizer(store.state.users.user)) &&
      !isAllowedModule(store.state.users.user, route)
    ) {
      return redirect('/forbidden')
    } else if (
      store.state.loggedIn &&
      !isAdmin(store.state.users.user) &&
      !isVisualizer(store.state.users.user)
    ) {
      return redirect('/forbidden')
    }
  }
}

// Vistas abiertas solo para 'superadmin', 'admin', 'editor'
function isAdmin(user) {
  return (
    user &&
    user.role &&
    (includes(['superadmin', 'admin', 'editor', 'operator'], user.role) ||
      user.auxRole === 'officer')
  )
}

function isVisualizer(user) {
  return user && user.auxRole && user.auxRole === 'visualizer'
}

function isAllowedModule(user, route) {
  let moduleId = route.params.moduleId
  if (route.query && route.query.moduleId) moduleId = route.query.moduleId
  const result = find(user.availableModules, (availableModule) => {
    return availableModule.module === moduleId
  })
  return !!result
}

function isSuperAdmin(user) {
  return user && user.role && user.role === 'superadmin'
}
