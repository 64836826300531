import includes from 'lodash/includes'

export default function ({ store, redirect, route }) {
  if (
    store.state.loggedIn &&
    store.state.users.user &&
    (includes(['admin', 'operator', 'editor'], store.state.users.user.role) ||
      store.state.users.user.auxRole === 'officer' ||
      store.state.users.user.auxRole === 'institutional') &&
    !route.fullPath.includes('PasswordFuncionarios') &&
    !route.fullPath.includes('PasswordInstitucionales') &&
    !route.fullPath.includes('forbidden') &&
    store.state.users.user.changedPassword === false
  ) {
    return redirect('/forbidden')
  }
}
