
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-component',
  props: ['type', 'value', 'obj'],
  computed: {
    rules() {
      return [this.value || this.$t('required')]
    },
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }, // listen to @input="handler"
    },
  },
}
