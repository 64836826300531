
import cloneDeep from 'lodash/cloneDeep'
import LoaderNotifyImage from '@/assets/images/LoaderNotify.svg'

export default {
  name: 'LoaderNotify',
  data() {
    return {
      loaderNotifyImg: LoaderNotifyImage,
      show: '',
      loaderNotify: {
        type: '',
        texto: [],
        timeout: -1,
        result: null,
        finish: null,
        query: '',
        path: '',
        redirect: false,
        titleRedirect: '',
        download: false,
        titleButton: null,
        pathButton: null,
        cancelButton: false,
      },
      textLoader: {
        text: '',
        show: false,
        index: 0,
        time: null,
      },
      indexLastVisible: null,
      absolute: true,
      overlay: false,
      showButton: false,
      tiempoRestante: {
        hora: 0,
        minuto: 0,
        segundo: 0,
        segundos: 0,
        activo: false,
      },
      eventRedirect: false,
      messages: [
        'Estamos procesando tu pago, por favor espera.',
        'No cierres la ventana ni refresques, seguimos trabajando.',
        'Estamos gestionando tu pago, espera un momento.',
        'Nos estamos tardando más de lo esperado, disculpa los inconvenientes.',
        'Tu pago aún se encuentra en proceso, por favor no cierres la ventana.',
      ],
      disableCancelButton: false,
    }
  },
  computed: {
    // Función para obtener el último índice visible de los textos
    indexLastVisibleComputed() {
      return this.loaderNotify.texto.length - 1
    },
  },
  watch: {
    'loaderNotify.cancelButton'(val) {
      this.disableCancelButton = !val
    },
  },
  async mounted() {
    const self = this
    this.unsubscribe = await this.$store.subscribe(async (mutation, state) => {
      const stateLocal = cloneDeep(state.loaderNotify)
      if (
        mutation.type === 'loaderNotify/SHOW_LOADER' ||
        mutation.type === 'loaderNotify/SHOW_TEXT' ||
        mutation.type === 'loaderNotify/FINISH' ||
        mutation.type === 'loaderNotify/ADD_TEXT' ||
        mutation.type === 'loaderNotify/RESULT' ||
        mutation.type === 'loaderNotify/SET_CONFIG_URL' ||
        mutation.type === 'loaderNotify/SHOW_BUTTON'
      ) {
        if (mutation.type === 'loaderNotify/SHOW_LOADER') {
          this.loaderNotify.texto = []
          this.eventRedirect = false
          this.loaderNotify.redirect = false
          this.loaderNotify.titleRedirect = ''
          this.loaderNotify.path = ''
          this.tiempoRestante.activo = false
          this.loaderNotify.titleButton = null
          this.loaderNotify.pathButton = null
        }

        // Si las mutaciones corresponden a este evento, lo dejo pasar
        this.loaderNotify.texto = stateLocal.loaderNotify.texto
        this.loaderNotify.type = stateLocal.loaderNotify.type
        this.loaderNotify.timeout = stateLocal.loaderNotify.timeout
        this.show = stateLocal.show
        this.loaderNotify.result = stateLocal.loaderNotify.result
        this.loaderNotify.finish = stateLocal.loaderNotify.finish
        this.loaderNotify.download = stateLocal.loaderNotify.download
        this.loaderNotify.titleButton = stateLocal.loaderNotify.titleButton
        this.loaderNotify.pathButton = stateLocal.loaderNotify.pathButton
        this.loaderNotify.cancelButton = stateLocal.loaderNotify.cancelButton

        this.showButton = false

        const tiempoCorriendo = null
        if (tiempoCorriendo === null && this.loaderNotify.finish !== true) {
          // Si esta acción, o tiempo aún no está corriendo, entonces lo dejamos pasar
          const tiempoCorriendo = setInterval(async function () {
            let textoActual = false
            await self.loaderNotify.texto.forEach(function (t, index) {
              if (t.visible === false && textoActual === false) {
                // Si en el intervalo de repeticiones, el texto del índice del arreglo aún no se muestra y es diferente al último que se está mostrando
                textoActual = true
                self.indexLastVisible = index
                self.$store.commit('loaderNotify/SHOW_TEXT', {
                  visible: true,
                  index,
                })
              }
            })

            if (self.loaderNotify.result === true) {
              // Si desde el front ya se tiene un resultado, es decir, se quiere finalizar, finalizamos el contador
              clearInterval(tiempoCorriendo)
              setTimeout(function () {
                // Esperamos un tiempo para que no se vea brusco, para mostrar el último elemento
                self.indexLastVisible = self.loaderNotify.texto.length - 1
                self.$store.commit('loaderNotify/SHOW_TEXT', {
                  visible: true,
                  index: self.indexLastVisible,
                })
              }, 2000)

              self.$store.commit('loaderNotify/FINISH', {
                // Y finalizamos, esto es para que se muestre el icono
                finish: true,
              })
            }
            if (self.loaderNotify.finish === true) {
              // Si ya finalizó, el icono se está mostrando y sólo le damos un tiempo antes de cerrar la ventana
              /* setTimeout(function () {
                self.$store.commit('loaderNotify/CLOSE_LOADER')
                // self.unsubscribe()
              }, 5000) */

              self.loaderNotify.path = stateLocal.loaderNotify.path
              self.loaderNotify.query = stateLocal.loaderNotify.query

              // this.showButton = true
            }
          }, 2000)
        }
      } else if (mutation.type === 'loaderNotify/CLOSE_LOADER') {
        this.show = stateLocal.show
        this.eventRedirect = false
        this.loaderNotify.redirect = false
        this.loaderNotify.titleRedirect = ''
        this.loaderNotify.path = ''
      } else if (mutation.type === 'loaderNotify/REDIRECT') {
        this.eventRedirect = true
        this.loaderNotify.redirect = stateLocal.loaderNotify.redirect
        this.loaderNotify.titleRedirect = stateLocal.loaderNotify.titleRedirect
        this.loaderNotify.path = stateLocal.loaderNotify.path
        await setTimeout(() => {
          this.contadorTiempo()
        }, 1000)
      } else if (mutation.type === 'loaderNotify/SET_LOADER') {
        this.show = stateLocal.show
        this.loaderNotify = stateLocal.loaderNotify

        this.indexLastVisible = stateLocal.indexLastVisible
        this.absolute = stateLocal.absolute
        this.overlay = stateLocal.overlay
        this.showButton = stateLocal.showButton

        this.tiempoRestante = stateLocal.tiempoRestante

        if (this.loaderNotify.redirect === true) {
          this.eventRedirect = true
          this.loaderNotify.redirect = stateLocal.loaderNotify.redirect
          this.loaderNotify.titleRedirect =
            stateLocal.loaderNotify.titleRedirect
          this.loaderNotify.path = stateLocal.loaderNotify.path
          if (this.tiempoRestante.segundos > 0) {
            await setTimeout(() => {
              this.contadorTiempo()
            }, 1000)
          }
        }
        if (self.loaderNotify.finish === true) {
          // Si ya finalizó, el icono se está mostrando y sólo le damos un tiempo antes de cerrar la ventana
          /* setTimeout(function () {
                self.$store.commit('loaderNotify/CLOSE_LOADER')
                // self.unsubscribe()
              }, 5000) */

          self.loaderNotify.path = stateLocal.loaderNotify.path
          self.loaderNotify.query = stateLocal.loaderNotify.query
          // this.showButton = true
        }
      } else if (mutation.type === 'loaderNotify/SHOW_TEXT_LOADER') {
        this.textLoader = stateLocal.textLoader
        self.setText()
        this.textLoader.time = setInterval(function () {
          self.setText()
        }, 10000)
      } else if (mutation.type === 'loaderNotify/SET_TEXT_LOADER') {
        this.textLoader = stateLocal.textLoader
        if (this.textLoader.show === false) {
          clearInterval(this.textLoader.time)
          this.textLoader = {
            text: '',
            show: false,
            index: 0,
            time: null,
          }
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    setText() {
      const self = this
      let index = self.textLoader.index + 1
      if (index === self.messages.length - 1) {
        index = 0
      }
      self.textLoader.index = index
      self.textLoader.text = self.messages[index]
      self.$store.commit('loaderNotify/SET_TEXT_LOADER', {
        textLoader: {
          show: self.textLoader.show,
          text: self.textLoader.text,
          index: self.textLoader.index,
        },
      })
    },
    contadorTiempo() {
      const self = this
      const tiempo = {
        hora: 0,
        minuto: 0,
        segundo: 10,
        segundos: 10,
        activo: true,
      }
      const tiempoCorriendo = setInterval(function () {
        // Segundos
        if (tiempo.segundos > 0) {
          tiempo.segundo--
          tiempo.segundos--
          if (tiempo.segundo === -1) {
            tiempo.segundo = 59
            tiempo.minuto--
          }

          // Minutos
          if (tiempo.minuto === -1) {
            tiempo.minuto = 59
            tiempo.hora--
          }
        } else {
          // await setTimeout(() => {

          window.open(self.loaderNotify.path, '_blank')
          clearInterval(tiempoCorriendo)
          self.loaderNotify.result = true
          self.loaderNotify.redirect = false

          // self.loaderNotify.titleRedirect = ''
          // }, 1000)
        }

        tiempo.hora =
          tiempo.hora < 10 ? '0' + parseInt(tiempo.hora) : parseInt(tiempo.hora)
        tiempo.minuto =
          tiempo.minuto < 10
            ? '0' + parseInt(tiempo.minuto)
            : parseInt(tiempo.minuto)
        tiempo.segundo =
          tiempo.segundo < 10
            ? '0' + parseInt(tiempo.segundo)
            : parseInt(tiempo.segundo)
        self.tiempoRestante = tiempo
      }, 1000)
    },
    closeCard() {
      this.show = false
    },
    handleClick() {
      if (this.loaderNotify.path !== '' && this.eventRedirect === false) {
        this.$router.push({
          path: this.loaderNotify.path,
          query: this.loaderNotify.query,
        })
      }

      this.$store.commit('loaderNotify/CLOSE_LOADER')
    },
    setData() {
      this.$store.commit('loaderNotify/SET_LOADER', {
        show: this.show,
        loaderNotify: this.loaderNotify,
        indexLastVisible: this.indexLastVisible,
        absolute: this.absolute,
        overlay: this.overlay,
        showButton: this.showButton,
        tiempoRestante: this.tiempoRestante,
        eventRedirect: this.eventRedirect,
      })
    },
    cancelSubmit() {
      this.disableCancelButton = true
      this.$store.dispatch('loaderNotify/cancel_process', {
        cancelProcess: true,
      })
    },
  },
}
