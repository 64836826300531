
export default {
  name: 'LoaderFull',
  data() {
    return {
      show: false,
      absolute: true,
      overlay: false,
    }
  },

  async mounted() {
    const self = this
    this.unsubscribe = await this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'loaderFull/SHOW_LOADER') {
        // Si las mutaciones corresponden a este evento, lo dejo pasar
        this.show = state.loaderFull.show
      } else if (mutation.type === 'loaderFull/CLOSE_LOADER') {
        setTimeout(function () {
          self.show = state.loaderFull.show
        }, 1000)
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },
}
