import find from 'lodash/find'

export default function ({ store, redirect }) {
  if (
    store.state.loggedIn &&
    !isSuperadmin(store.state.users.user) &&
    !isVisualizer(store.state.users.user)
  ) {
    return redirect('/forbidden')
  }
}

// Vistas abiertas solo para 'superadmin'
function isSuperadmin(user) {
  return user && user.role && user.role === 'superadmin'
}

function isVisualizer(user) {
  let isAdmin = false
  if (user.auxRole && user.auxRole === 'visualizer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'dashboard'
    })
    isAdmin = !!hasModule
  }
  return isAdmin
}
