/**
 * @todo Homologate User firestore properties to camelcase
 */

import { cloneDeep } from 'lodash'
import { setDocFirestore, updateDocFirestore } from '@/services/firebaseWrite'
export interface User {
  birthdate: string
  created: string
  first_name: string
  last_name: string
  foto: string
  gender: string
  idUser: string
  phone: string
  picture: string
  public_address: string
  scholarity: string
  uid: string
  location: {
    lat: string
    lng: string
    address: string
    custom: string
    street: string
    num_ext: string
    num_int: string
    neighborhood: string
    betweenStreets: string
    references: string
  }
}

export const setUserDoc = async ($fire: any, id: string, body: any) => {
  if (body.typeProfile) {
    delete body.typeProfile
  }
  const data = {
    uid: id,
    ...body,
  } as User
  // validaciones

  return await setDocFirestore($fire, `users/${id}`, { ...data }, true)
}

export const getUserByPublicAddress = async (
  $fire: any,
  { publicAddress }: any
) => {
  return await $fire.firestore
    .collection('users')
    .where('public_address', '==', publicAddress)
    .get()
}

export const getUserByCurp = async ($fire: any, curp: string) => {
  const querySnapshot = await $fire.firestore
    .collection('users')
    .where('curp', '==', curp)
    .get()
  return querySnapshot.empty ? null : querySnapshot.docs[0].data()
}

export const getUserByEmail = async ($fire: any, email: string) => {
  return await $fire.firestore
    .collection('users')
    .where('email', '==', email)
    .get()
}

export const getUser = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('users').doc(id).get()
}

export const getUsers = async ($fire: any) => {
  return await $fire.firestore.collection('users').get()
}

export const setUserCidiLevel = async ($fire: any, id: string) => {
  // Función que realiza correctamente una escritura
  return await updateDocFirestore($fire, `users/${id}`, { levelCidi: 2 })
}

export const setUserRole = async (
  $fire: any,
  id: string,
  addAsCommunicator: boolean
) => {
  // Función que realiza correctamente una escritura
  return await updateDocFirestore($fire, `users/${id}`, {
    role: addAsCommunicator ? 'communicator' : '',
  })
}

export const publicAddressExist = async ($fire: any, publicAddress: string) => {
  return await $fire.firestore
    .collection('users')
    .where('public_address', '==', publicAddress)
    .get()
    .then((querySnapshot: any) => {
      return !querySnapshot.empty
    })
}

// Only for companies
export const getCompany = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('companies').doc(id).get()
}

export const publicAddressCompanyExist = async (
  $fire: any,
  publicAddress: string
) => {
  return await $fire.firestore
    .collection('companies')
    .where('public_address', '==', publicAddress)
    .get()
    .then((querySnapshot: any) => {
      return !querySnapshot.empty
    })
}

export const updateCompanyDoc = async (
  $fire: any,
  id: string,
  body: any,
  publicAddress: string
) => {
  if (body.typeProfile) {
    delete body.typeProfile
  }

  // This is for update localStorage with the new data
  localStorage.setItem('currentProfile', JSON.stringify(body))

  return await updateDocFirestore($fire, `companies/${id}`, {
    public_address: publicAddress,
  })
}

export const setCompanyDoc = async ($fire: any, id: string, body: any) => {
  if (body.typeProfile) {
    delete body.typeProfile
  }
  const dataForFirebase = cloneDeep(body)
  delete dataForFirebase.company
  delete dataForFirebase.originalUser
  const data = {
    uid: id,
    ...dataForFirebase,
  } as User

  // This is for update localStorage with the new data
  localStorage.setItem('currentProfile', JSON.stringify(body))

  return await setDocFirestore($fire, `companies/${id}`, { ...data }, true)
}

export const validateLogin = async ($fire: any, email: string) => {
  if (!email) {
    throw new Error('The function was called with no arguments (no email)')
  }

  try {
    // Consultar en Firestore si el usuario existe
    const userRef = $fire.firestore
      .collection('users')
      .where('email', '==', email)
    const querySnapshot = await userRef.get()

    if (!querySnapshot.empty) {
      const firestoreUser = querySnapshot.docs[0].data()

      if (firestoreUser.email === email) {
        return 'isValid' // Usuario válido
      } else {
        return 'notValid' // Usuario en Auth pero no coincide en Firestore
      }
    } else {
      return 'notValid' // Usuario no encontrado en Firestore
    }
  } catch (error) {
    console.error('Error during validateLogin:', error)
    return 'noAction' // Error general
  }
}
