
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'SidebarEditor',
  props: {
    dialogEditor: { type: Boolean, default: false },
    configMap: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      configuration: {},
      panel: null,
    }
  },
  watch: {
    configMap(val) {
      this.configuration = cloneDeep(val)
    },
  },

  mounted() {
    this.configuration = cloneDeep(this.configMap)
  },
  methods: {
    saveChanges() {
      this.$emit('setConfig', this.configuration)
    },
    setOpacity(layer, key) {
      if (layer.show === true) {
        this.configuration.selectedMap.data.maplayers[key].opacity = 25
      } else {
        this.configuration.selectedMap.data.maplayers[key].opacity = 0
      }
    },
    check(e) {
      e.cancelBubble = true
    },
  },
}
