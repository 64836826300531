
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-questions',
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      loading: false,
      answers: [],
      ruleVal: [(v) => !!v || this.$t('fieldRequired')],
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.answers = val
      }
    },
  },
  beforeMount() {
    this.loading = true
    if (
      this.obj.schema &&
      this.obj.schema.questions &&
      !this.obj.schema.disabled &&
      !this.value
    ) {
      this.addItem()
    } else {
      this.answers = this.value
    }
    this.loading = false
  },
  methods: {
    filesRule(question) {
      // eslint-disable-next-line prefer-const
      let rules = []
      if (question.acceptedFiles && question.acceptedFiles.length > 0) {
        rules.push(
          (value) =>
            question.acceptedFiles.includes(value?.type) ||
            !value ||
            question.acceptedFiles.includes(value?.name.split('.').pop()) ||
            this.$t('solo-se-aceptan')
        )
      } else {
        question.acceptedFiles = ['application/pdf']
        question.accept = question.acceptedFiles.join(', ')
        rules.push(
          (value) =>
            question.acceptedFiles.includes(value?.type) ||
            !value ||
            this.$t('solo-se-aceptan')
        )
      }

      if (question.maxFileSize) {
        rules.push(
          (value) =>
            !value ||
            value.size < question.maxFileSize * 1e6 ||
            `${this.$t('procedures.fileSize')} ${question.maxFileSize} MB`
        )
      } else {
        rules.push(
          (value) =>
            !value ||
            value.size < 2 * 1e6 ||
            `${this.$t('procedures.fileSize')} a 2 MB`
        )
      }

      return rules
    },
    updateValue() {
      this.$emit('input', this.answers)
    },
    addItem() {
      const questions = this.obj.schema.questions
      const answObj = {}
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i]
        answObj[question.id] = null
      }
      // Si esta en prevención, agregamos una bandera para indicarlo
      if (this.obj.schema.disableFile) {
        answObj.prevAdded = true
      }
      this.answers.push(answObj)
      this.$emit('input', this.answers)
    },
    removeItem(index) {
      if (this.obj.schema.softDelete) {
        this.$set(this.answers, index, {
          ...this.answers[index],
          deleted: true,
        })
      } else {
        this.answers.splice(index, 1)
      }
      this.$emit('input', this.answers)
    },
    getItems(question) {
      let items = []
      if (question.answersFromCatalog) {
        items = question.optionsCatalog
          ? question.optionsCatalog.map((option) => option.name)
          : []
      } else {
        items = question.options
      }
      return items
    },
  },
}
