
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ConfirmLevelUpDialog',
  components: {},
  props: {
    dialogBackAfip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loaderModal: true,
    loaderButton: false,
  }),
  computed: {
    activeDialog(): boolean {
      return this.dialogBackAfip
    },
    ...mapState({
      user: (state: any) => state.users.user,
    }),
    ...mapGetters({
      levelUpdated: 'users/levelUpdated',
    }),
  },
  watch: {
    'user.levelUpdated'() {
      // eslint-disable-next-line no-console
      console.info('Cambio levelUpdated')
      // eslint-disable-next-line no-console
      console.info(this.user.levelUpdated)
      this.loaderModal = false
    },
  },
  methods: {
    async closeSessionAutenticar() {
      this.loaderButton = true
      const reino = localStorage.getItem('reino')
      if (reino) {
        await this.$autenticar.logout()
      }
    },
  },
})
