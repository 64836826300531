import kebabCase from 'lodash/kebabCase'
import split from 'lodash/split'
import map from 'lodash/map'
import join from 'lodash/join'

export const getFullName = function (firstName, lastName, lastName2 = '') {
  if (firstName && lastName) {
    return firstName + ' ' + lastName + (lastName2 ? ' ' + lastName2 : '')
  } else if (firstName) {
    return firstName
  } else {
    return ''
  }
}

export const sliceArrayIntoChunks = function (arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export const isTokenExpired = (token) => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]))
  const expirationTime = decodedToken.exp
  const currentTime = Math.floor(Date.now() / 1000) // Convertimos a segundos

  return expirationTime < currentTime
}

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent
  let browserName = 'Unknown'

  if (userAgent.includes('Firefox')) {
    browserName = 'Firefox'
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera'
  } else if (userAgent.includes('Trident')) {
    browserName = 'Internet Explorer'
  } else if (userAgent.includes('Edge')) {
    browserName = 'Edge'
  } else if (userAgent.includes('Chrome')) {
    browserName = 'Chrome'
  } else if (userAgent.includes('Safari')) {
    browserName = 'Safari'
  }

  return browserName
}

export const maskToRegex = (mask) => {
  // Caracteres reservados para realizar la mascara
  const maskCharacters = {
    '#': '[0-9]',
    A: '[a-zA-Z]',
    N: '[a-zA-Z0-9]',
    X: '\\S',
  }

  let regexPat = '^('
  for (const char of mask) {
    // Si el caracter esta dentro de los caracteres reservados, lo reemplazamos con su patrón de regex
    // correspondiente. Si el caracter es un caracter especial de regex, le agregamos // para tratarlo
    // como caracter y no como parte del patron de regex y si no es ninguno de los dos, agregamos el
    // caracter directamente
    if (maskCharacters[char]) {
      regexPat += maskCharacters[char]
    } else if ('^$\\.*+?()[]{}|'.includes(char)) {
      regexPat += '\\' + char
    } else {
      regexPat += char
    }
  }
  regexPat += ')?$'

  return new RegExp(regexPat)
}

export const actionIsAllowed = (action, permissions, role) => {
  let isAllowed = false
  const isAdminOrEditor = ['admin', 'editor'].includes(role)
  if (role === 'superadmin') {
    // Si el rol es de superadmin, lo dejamos hacer cualquier acción
    isAllowed = true
  } else if (isAdminOrEditor && !permissions) {
    // Si el usuario es admin o editor, pero no tiene permisos especificos,
    // entonces es un usuario default, se le permite hacer todas las acciones
    isAllowed = true
  } else if (isAdminOrEditor && permissions && permissions.length === 0) {
    // Si el usuario es admin o editor, pero el arreglo no tiene ningun permiso, permitimos
    // realizar cualquier acción
    isAllowed = true
  } else if (isAdminOrEditor && permissions && permissions.length > 0) {
    // Si el usuario tiene el arreglo de permisos especificos, revisamos para
    // ver si la accion es permitida
    isAllowed = permissions.includes(action)
  } else {
    isAllowed = true
  }
  return isAllowed
}

export const customSnakeCase = (s) => {
  return join(
    map(
      split(s, /(\d+)/), // Dividir el string en letras y números
      kebabCase // Aplicar kebabCase a cada parte
    ),
    '' // Unir sin separadores adicionales
  )
}
