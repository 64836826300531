import Vue from 'vue'
import capitalize from 'lodash/capitalize'

import { getAuth } from 'firebase/auth'

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getUserByEmail, User } from '@/services/User'

export default Vue.extend({
  name: 'SendEmailVerificationMixin',
  data() {
    return {
      userData: {
        user: {} as User,
      },
      loading: false,
      region: process.env.REGION || 'us-central1',
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
    }
  },
  methods: {
    async sendEmail(
      userEmail: string,
      subject: string,
      title: string,
      link: string,
      content: string,
      userName: string,
      subcontent: string,
      subcontent2: string
    ) {
      const englishInstance =
        process.env.FB_PROJECT_ID === 'unicef-certs' ||
        process.env.FB_PROJECT_ID === 'undp-f9c4e'
      const functions = getFunctions(getApp(), this.region)
      const sendEmailFunction = httpsCallable(functions, 'sendEmail')
      try {
        await sendEmailFunction({
          personalizations: [
            {
              to: [
                {
                  email: userEmail,
                },
              ],
              dynamic_template_data: {
                user: userName || userEmail,
                title,
                subject,
                link,
                content,
                language: englishInstance ? 'en' : 'es',
                button: this.$t('continue'),
                subcontent,
                subcontent2,
              },
              subject,
            },
          ],
        })
      } catch (error: any) {
        throw new Error(error)
        // console.error(error)
      }
    },
    async sendEmailMultipleUser(
      users: Array<Object>,
      subject: string,
      title: string,
      link: string,
      content: string,
      subcontent: string,
      subcontent2: string,
      button: string,
      name: string
    ) {
      const englishInstance =
        process.env.FB_PROJECT_ID === 'unicef-certs' ||
        process.env.FB_PROJECT_ID === 'undp-f9c4e'
      const functions = getFunctions(getApp(), this.region)
      const sendEmailFunction = httpsCallable(functions, 'sendEmail')
      try {
        await sendEmailFunction({
          personalizations: [
            {
              to: users,
              dynamic_template_data: {
                user: name,
                title,
                subject,
                link,
                content,
                language: englishInstance ? 'en' : 'es',
                button,
                subcontent,
                subcontent2,
              },
              subject,
            },
          ],
        })
      } catch (error: any) {
        throw new Error(error)
        // console.error(error)
      }
    },
    async sendEmailVerificationSengrid(
      modeEmail: String,
      email?: String,
      uid?: String,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      language?: String
    ): Promise<void> {
      const self = this
      this.$store.dispatch('loaderFull/open')
      this.loading = true
      const functions = getFunctions(getApp(), this.region)
      const sendEmailFunction = httpsCallable(functions, 'sendEmail')
      const createToken = httpsCallable(functions, 'jwt-createTokenEmail')
      try {
        const userEmail = email || getAuth().currentUser?.email
        let linkToVerify = null
        const subject =
          modeEmail === 'verifyEmail'
            ? this.$t('verifica-tu-correo')
            : this.$t('restablece-tu-contrasena')
        const existUser = await getUserByEmail(this.$fire, <string>userEmail)
        const userFirstName = existUser.docs[0]
          ? existUser.docs[0].data().first_name.split(' ')
          : ''
        const userName = capitalize(userFirstName[0])
        let notificationEmail: String | undefined | null = ''
        if (uid || !existUser.empty) {
          // Revisamos si el usuario tiene un correo de notificación
          // para mandarle el correo ahí
          const userData = existUser.docs[0].data()
          if (userData.notificationEmail) {
            notificationEmail = userData.notificationEmail
          } else {
            notificationEmail = userEmail
          }
          await createToken({
            uid: uid || existUser.docs[0].id,
            currentUrl: this.$store.state.currentUrl,
            mode: modeEmail,
          }).then((result) => {
            linkToVerify = result.data
          })
        } else {
          this.$store.dispatch('loaderFull/close')
          this.snackbar.show = true
          this.snackbar.text = <string>this.$t('sendEmail.emailNotRegister')
          this.snackbar.color = 'error'
        }
        if (linkToVerify) {
          let content = ''
          if (modeEmail === 'resetPassword') {
            content = <string>this.$t('sendEmail.visitLink')
          } else {
            content = <string>this.$t('sendEmail.visitLinkFinishProcess')
          }

          await sendEmailFunction({
            personalizations: [
              {
                to: [
                  {
                    email: notificationEmail,
                  },
                ],
                dynamic_template_data: {
                  user: userName,
                  title: subject,
                  subject,
                  link: linkToVerify,
                  content,
                  button: <string>self.$t('continue'),
                  warning:
                    modeEmail === 'verifyEmail'
                      ? ''
                      : self.$t('sendEmail.notRequest'),
                },
                subject,
              },
            ],
          })
          this.$store.dispatch('loaderFull/close')
          this.snackbar.show = true
          this.snackbar.text = <string>self.$t('sendEmail.haveSendEmail')
          this.snackbar.color = 'success'
          setTimeout(() => {
            this.$router.push({
              path: '/',
            })
          }, 2500)
        }
      } catch {
        this.$store.dispatch('loaderFull/close')
        this.snackbar.show = true
        this.snackbar.text = <string>self.$t('sendEmail.emailNotSend')

        this.snackbar.color = 'error'
      }
      this.$store.dispatch('loaderFull/close')

      this.loading = false
    },
  },
})
